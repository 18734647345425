"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const vue_router_1 = require("vue-router");
const vuex_1 = require("vuex");
const bannerStore_1 = require("../../stores/banner/bannerStore");
const toastStore_1 = require("../../stores/toast/toastStore");
const vue_3 = require("vue");
const file_types_1 = require("../../store/bannerFiles/file-types");
const banner_statuses_1 = require("../../stores/banner/banner-statuses");
exports.default = (0, vue_1.defineComponent)({
    __name: 'UniversalBanner',
    setup(__props) {
        const router = (0, vue_router_1.useRouter)();
        const store = (0, vuex_1.useStore)();
        const bannerStore = (0, bannerStore_1.useBannerStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const selectedBannerFiles = (0, vue_3.computed)(() => store.getters["bannerFiles/bannerFiles"]);
        const url = (0, vue_3.ref)(null);
        const imgUrl = (0, vue_3.ref)(null);
        (0, vue_3.onBeforeUnmount)(() => {
            bannerStore.reset();
        });
        (0, vue_3.onBeforeMount)(() => {
            bannerStore.reset();
            bannerStore
                .getRandomBanners(router, { status: banner_statuses_1.ACTIVE, displayUniversalBanner: 1 })
                .then(() => {
                if (bannerStore.totalBanners) {
                    store
                        .dispatch("bannerFiles/getBannerFiles", {
                        pagination: false,
                        banner: bannerStore.banners[0].id,
                        type: file_types_1.UNIVERSAL
                    }).then(() => {
                        if (selectedBannerFiles.value.length) {
                            imgUrl.value = selectedBannerFiles.value[0].contentUrl;
                        }
                        url.value = bannerStore.banners[0].universalUrl;
                    })
                        .catch((error) => {
                        toastStore.addErrorToast(error.toString());
                    });
                }
            })
                .catch((error) => toastStore.addErrorToast(error.toString()));
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                href: url.value,
                target: "_blank"
            }, [
                (imgUrl.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("img", {
                        key: 0,
                        class: "w-full mb-4",
                        src: imgUrl.value,
                        alt: ""
                    }, null, 8, _hoisted_2))
                    : (0, vue_2.createCommentVNode)("", true)
            ], 8, _hoisted_1));
        };
    }
});
