"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerPermissionEnum = exports.OpenOrderPermissionEnum = exports.OrderPermissionEnum = exports.PackingSlipPermissionEnum = exports.CustomerPricingFilterPermissionEnum = exports.CustomerPricingPermissionEnum = exports.QuotationPermissionEnum = exports.CarcassReturnPermissionEnum = exports.CustomerProductReturnPermissionEnum = exports.GuaranteeProductReturnPermissionEnum = exports.CouponPermissionEnum = exports.BannedProductSearchRulePermissionEnum = exports.BannedAxaptaProductImportRulePermissionEnum = exports.AcceptedContractPermissionEnum = exports.ContractPermissionEnum = exports.OrderLogPermissionEnum = exports.OrderInvoicePermissionEnum = exports.LogPermissionEnum = exports.TranslationPermissionEnum = exports.NewsletterPermissionEnum = exports.NewsFilePermissionEnum = exports.NewsPermissionEnum = exports.CustomerPermissionEnum = exports.UserGroupPermissionEnum = exports.UserPermissionEnum = void 0;
/* eslint-disable no-unused-vars */
var UserPermissionEnum;
(function (UserPermissionEnum) {
    UserPermissionEnum["USER_READ"] = "USER_READ";
    UserPermissionEnum["USER_WRITE"] = "USER_WRITE";
    UserPermissionEnum["SHOW_PASSENGER_TYRES"] = "SHOW_PASSENGER_TYRES";
    UserPermissionEnum["SHOW_TRUCK_TYRES"] = "SHOW_TRUCK_TYRES";
    UserPermissionEnum["SHOW_AGRICULTURAL_AND_INDUSTRIAL_TYRES"] = "SHOW_AGRICULTURAL_AND_INDUSTRIAL_TYRES";
    UserPermissionEnum["SHOW_FORKLIFT_TYRES"] = "SHOW_FORKLIFT_TYRES";
    UserPermissionEnum["SHOW_MOTORCYCLES_TYRES"] = "SHOW_MOTORCYCLES_TYRES";
    UserPermissionEnum["SHOW_RIMS_AND_TPMSS_BY_CAR_TYPE"] = "SHOW_RIMS_AND_TPMSS_BY_CAR_TYPE";
    UserPermissionEnum["SHOW_RIMS_BY_SIZE"] = "SHOW_RIMS_BY_SIZE";
    UserPermissionEnum["SHOW_TUBES_AND_TAPES"] = "SHOW_TUBES_AND_TAPES";
    UserPermissionEnum["SHOW_ACCESSORIES"] = "SHOW_ACCESSORIES";
    UserPermissionEnum["SHOW_SEARCH_BY_SKU"] = "SHOW_SEARCH_BY_SKU";
    UserPermissionEnum["SHOW_SPARE_WHEEL"] = "SHOW_SPARE_WHEEL";
    UserPermissionEnum["SHOW_INFORMATON_PAGE"] = "SHOW_INFORMATON_PAGE";
    UserPermissionEnum["BALANCE_READ"] = "BALANCE_READ";
})(UserPermissionEnum = exports.UserPermissionEnum || (exports.UserPermissionEnum = {}));
var UserGroupPermissionEnum;
(function (UserGroupPermissionEnum) {
    UserGroupPermissionEnum["USER_GROUP_READ"] = "USER_GROUP_READ";
    UserGroupPermissionEnum["USER_GROUP_WRITE"] = "USER_GROUP_WRITE";
})(UserGroupPermissionEnum = exports.UserGroupPermissionEnum || (exports.UserGroupPermissionEnum = {}));
var CustomerPermissionEnum;
(function (CustomerPermissionEnum) {
    CustomerPermissionEnum["MANDATORY_COLLECTION_COST"] = "MANDATORY_COLLECTION_COST";
    CustomerPermissionEnum["MANDATORY_CREDIT_LIMIT"] = "MANDATORY_CREDIT_LIMIT";
    CustomerPermissionEnum["ORDER_DENIED"] = "ORDER_DENIED";
    CustomerPermissionEnum["SERVED_BY_CENTER"] = "SERVED_BY_CENTER";
    CustomerPermissionEnum["SHOW_ONLY_LIST_PRICE_ON_WEB"] = "SHOW_ONLY_LIST_PRICE_ON_WEB";
    CustomerPermissionEnum["ALLOW_USER_TRANSFER"] = "ALLOW_USER_TRANSFER";
    CustomerPermissionEnum["SHOW_CONTINENTAL_STOCK"] = "SHOW_CONTINENTAL_STOCK";
    CustomerPermissionEnum["SHOW_GOODYEAR_STOCK"] = "SHOW_GOODYEAR_STOCK";
    CustomerPermissionEnum["CUSTOMER_READ"] = "CUSTOMER_READ";
    CustomerPermissionEnum["CUSTOMER_WRITE"] = "CUSTOMER_WRITE";
    CustomerPermissionEnum["MPH_PARTNER"] = "MPH_PARTNER";
    CustomerPermissionEnum["NAD_PARTNER"] = "NAD_PARTNER";
    CustomerPermissionEnum["EXPORT_PARTNER"] = "EXPORT_PARTNER";
})(CustomerPermissionEnum = exports.CustomerPermissionEnum || (exports.CustomerPermissionEnum = {}));
var NewsPermissionEnum;
(function (NewsPermissionEnum) {
    NewsPermissionEnum["NEWS_READ"] = "NEWS_READ";
    NewsPermissionEnum["NEWS_WRITE"] = "NEWS_WRITE";
})(NewsPermissionEnum = exports.NewsPermissionEnum || (exports.NewsPermissionEnum = {}));
var NewsFilePermissionEnum;
(function (NewsFilePermissionEnum) {
    NewsFilePermissionEnum["NEWS_FILE_READ"] = "NEWS_FILE_READ";
    NewsFilePermissionEnum["NEWS_FILE_WRITE"] = "NEWS_FILE_WRITE";
})(NewsFilePermissionEnum = exports.NewsFilePermissionEnum || (exports.NewsFilePermissionEnum = {}));
var NewsletterPermissionEnum;
(function (NewsletterPermissionEnum) {
    NewsletterPermissionEnum["NEWSLETTER_READ"] = "NEWSLETTER_READ";
    NewsletterPermissionEnum["NEWSLETTER_WRITE"] = "NEWSLETTER_WRITE";
})(NewsletterPermissionEnum = exports.NewsletterPermissionEnum || (exports.NewsletterPermissionEnum = {}));
var TranslationPermissionEnum;
(function (TranslationPermissionEnum) {
    TranslationPermissionEnum["TRANSLATION_READ"] = "TRANSLATION_READ";
    TranslationPermissionEnum["TRANSLATION_WRITE"] = "TRANSLATION_WRITE";
})(TranslationPermissionEnum = exports.TranslationPermissionEnum || (exports.TranslationPermissionEnum = {}));
var LogPermissionEnum;
(function (LogPermissionEnum) {
    LogPermissionEnum["LOG_READ"] = "LOG_READ";
    LogPermissionEnum["LOG_WRITE"] = "LOG_WRITE";
})(LogPermissionEnum = exports.LogPermissionEnum || (exports.LogPermissionEnum = {}));
var OrderInvoicePermissionEnum;
(function (OrderInvoicePermissionEnum) {
    OrderInvoicePermissionEnum["ORDER_INVOICE_READ"] = "ORDER_INVOICE_READ";
    OrderInvoicePermissionEnum["ORDER_INVOICE_WRITE"] = "ORDER_INVOICE_WRITE";
})(OrderInvoicePermissionEnum = exports.OrderInvoicePermissionEnum || (exports.OrderInvoicePermissionEnum = {}));
var OrderLogPermissionEnum;
(function (OrderLogPermissionEnum) {
    OrderLogPermissionEnum["ORDER_LOG_READ"] = "ORDER_LOG_READ";
    OrderLogPermissionEnum["ORDER_LOG_WRITE"] = "ORDER_LOG_WRITE";
})(OrderLogPermissionEnum = exports.OrderLogPermissionEnum || (exports.OrderLogPermissionEnum = {}));
var ContractPermissionEnum;
(function (ContractPermissionEnum) {
    ContractPermissionEnum["CONTRACT_READ"] = "CONTRACT_READ";
    ContractPermissionEnum["CONTRACT_WRITE"] = "CONTRACT_WRITE";
})(ContractPermissionEnum = exports.ContractPermissionEnum || (exports.ContractPermissionEnum = {}));
var AcceptedContractPermissionEnum;
(function (AcceptedContractPermissionEnum) {
    AcceptedContractPermissionEnum["ACCEPTED_CONTRACT_READ"] = "ACCEPTED_CONTRACT_READ";
    AcceptedContractPermissionEnum["ACCEPTED_CONTRACT_WRITE"] = "ACCEPTED_CONTRACT_WRITE";
})(AcceptedContractPermissionEnum = exports.AcceptedContractPermissionEnum || (exports.AcceptedContractPermissionEnum = {}));
var BannedAxaptaProductImportRulePermissionEnum;
(function (BannedAxaptaProductImportRulePermissionEnum) {
    BannedAxaptaProductImportRulePermissionEnum["BANNED_AXAPTA_PRODUCT_IMPORT_RULE_READ"] = "BANNED_AXAPTA_PRODUCT_IMPORT_RULE_READ";
})(BannedAxaptaProductImportRulePermissionEnum = exports.BannedAxaptaProductImportRulePermissionEnum || (exports.BannedAxaptaProductImportRulePermissionEnum = {}));
var BannedProductSearchRulePermissionEnum;
(function (BannedProductSearchRulePermissionEnum) {
    BannedProductSearchRulePermissionEnum["BANNED_PRODUCT_SEARCH_RULE_READ"] = "BANNED_PRODUCT_SEARCH_RULE_READ";
})(BannedProductSearchRulePermissionEnum = exports.BannedProductSearchRulePermissionEnum || (exports.BannedProductSearchRulePermissionEnum = {}));
var CouponPermissionEnum;
(function (CouponPermissionEnum) {
    CouponPermissionEnum["COUPON_READ"] = "COUPON_READ";
    CouponPermissionEnum["COUPON_WRITE"] = "COUPON_WRITE";
})(CouponPermissionEnum = exports.CouponPermissionEnum || (exports.CouponPermissionEnum = {}));
var GuaranteeProductReturnPermissionEnum;
(function (GuaranteeProductReturnPermissionEnum) {
    GuaranteeProductReturnPermissionEnum["GUARANTEE_PRODUCT_RETURN_READ"] = "GUARANTEE_PRODUCT_RETURN_READ";
    GuaranteeProductReturnPermissionEnum["GUARANTEE_PRODUCT_RETURN_WRITE"] = "GUARANTEE_PRODUCT_RETURN_WRITE";
})(GuaranteeProductReturnPermissionEnum = exports.GuaranteeProductReturnPermissionEnum || (exports.GuaranteeProductReturnPermissionEnum = {}));
var CustomerProductReturnPermissionEnum;
(function (CustomerProductReturnPermissionEnum) {
    CustomerProductReturnPermissionEnum["CUSTOMER_PRODUCT_RETURN_READ"] = "CUSTOMER_PRODUCT_RETURN_READ";
    CustomerProductReturnPermissionEnum["CUSTOMER_PRODUCT_RETURN_WRITE"] = "CUSTOMER_PRODUCT_RETURN_WRITE";
})(CustomerProductReturnPermissionEnum = exports.CustomerProductReturnPermissionEnum || (exports.CustomerProductReturnPermissionEnum = {}));
var CarcassReturnPermissionEnum;
(function (CarcassReturnPermissionEnum) {
    CarcassReturnPermissionEnum["CARCASS_RETURN_READ"] = "CARCASS_RETURN_READ";
    CarcassReturnPermissionEnum["CARCASS_RETURN_WRITE"] = "CARCASS_RETURN_WRITE";
    CarcassReturnPermissionEnum["CARCASS_RETURN_SHOW_ALL"] = "CARCASS_RETURN_SHOW_ALL";
})(CarcassReturnPermissionEnum = exports.CarcassReturnPermissionEnum || (exports.CarcassReturnPermissionEnum = {}));
var QuotationPermissionEnum;
(function (QuotationPermissionEnum) {
    QuotationPermissionEnum["QUOTATION_READ"] = "QUOTATION_READ";
    QuotationPermissionEnum["QUOTATION_WRITE"] = "QUOTATION_WRITE";
})(QuotationPermissionEnum = exports.QuotationPermissionEnum || (exports.QuotationPermissionEnum = {}));
var CustomerPricingPermissionEnum;
(function (CustomerPricingPermissionEnum) {
    CustomerPricingPermissionEnum["CUSTOMER_PRICING_READ"] = "CUSTOMER_PRICING_READ";
    CustomerPricingPermissionEnum["CUSTOMER_PRICING_WRITE"] = "CUSTOMER_PRICING_WRITE";
})(CustomerPricingPermissionEnum = exports.CustomerPricingPermissionEnum || (exports.CustomerPricingPermissionEnum = {}));
var CustomerPricingFilterPermissionEnum;
(function (CustomerPricingFilterPermissionEnum) {
    CustomerPricingFilterPermissionEnum["CUSTOMER_PRICING_FILTER_READ"] = "CUSTOMER_PRICING_FILTER_READ";
    CustomerPricingFilterPermissionEnum["CUSTOMER_PRICING_FILTER_WRITE"] = "CUSTOMER_PRICING_FILTER_WRITE";
})(CustomerPricingFilterPermissionEnum = exports.CustomerPricingFilterPermissionEnum || (exports.CustomerPricingFilterPermissionEnum = {}));
var PackingSlipPermissionEnum;
(function (PackingSlipPermissionEnum) {
    PackingSlipPermissionEnum["PACKING_SLIP_READ"] = "PACKING_SLIP_READ";
})(PackingSlipPermissionEnum = exports.PackingSlipPermissionEnum || (exports.PackingSlipPermissionEnum = {}));
var OrderPermissionEnum;
(function (OrderPermissionEnum) {
    OrderPermissionEnum["ORDER_READ"] = "ORDER_READ";
})(OrderPermissionEnum = exports.OrderPermissionEnum || (exports.OrderPermissionEnum = {}));
var OpenOrderPermissionEnum;
(function (OpenOrderPermissionEnum) {
    OpenOrderPermissionEnum["OPEN_ORDER_READ"] = "OPEN_ORDER_READ";
})(OpenOrderPermissionEnum = exports.OpenOrderPermissionEnum || (exports.OpenOrderPermissionEnum = {}));
var BannerPermissionEnum;
(function (BannerPermissionEnum) {
    BannerPermissionEnum["BANNER_READ"] = "BANNER_READ";
    BannerPermissionEnum["BANNER_WRITE"] = "BANNER_WRITE";
})(BannerPermissionEnum = exports.BannerPermissionEnum || (exports.BannerPermissionEnum = {}));
